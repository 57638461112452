<template>
  <div id="certificate">
    <Header now-nav="/relation" ref="Header"></Header>
    <div class="headerBox">
      <img :src="baseUrl+'imges/books/certificateBG.png'" alt="" class="imgCertificateHead">
      <img :src="baseUrl+'imges/books/mobileBG.png'" alt="" class="MobileBG">

      <h4  data-aos="fade-up" data-aos-duration="1000">荣誉资质</h4>
      <p  data-aos="fade-up" data-aos-duration="1000">蓉视通科技专注于智慧管廊领域的研究与开发，多次荣获各机构颁发荣誉与专利证书，成为受人瞩目的工业物联网软硬件制造商。</p>
    </div>

    <!--  内容区  -->
    <div class="contentBox" >
      <div class="navHead">
        <el-breadcrumb separator-class="el-icon-arrow-right" class="navHead_text">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/about' }">公司简介</el-breadcrumb-item>
          <el-breadcrumb-item>{{ thisBookName }}</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="productBox">
        <div class="leftProductPC">
          <p @click="mouseEnter('tab1')" :class="tab1?'isActive':''" class="tab1Class">荣誉证书</p>
          <p @click="mouseEnter('tab2')" :class="tab2?'isActive':''">专利证书</p>
          <p @click="mouseEnter('tab3')" :class="tab3?'isActive':''">资质证书</p>
        </div>
        <div class="leftProductMobile"  :style="status==='fixed'?'position: fixed;top: 64px;left: 0':''">
          <p @click="mouseEnter('tab1', 'Top')" :class="tab1?'isActive':''" class="tab1Class">荣誉证书</p>
          <p @click="mouseEnter('tab2', 'Top')" :class="tab2?'isActive':''">专利证书</p>
          <p @click="mouseEnter('tab3', 'Top')" :class="tab3?'isActive':''">资质证书</p>
        </div>

        <div class="rightProduct">
          <div v-if="tab1" class="tab1">
            <div v-if="num1">
              <img :src="baseUrl+'imges/books/honor1.png'" alt="">
              <img :src="baseUrl+'imges/books/honor2.png'" alt="">
              <img :src="baseUrl+'imges/books/honor3.png'" alt="">
              <img :src="baseUrl+'imges/books/honor4.png'" alt="">
              <img :src="baseUrl+'imges/books/honor5.png'" alt="">
              <img :src="baseUrl+'imges/books/honor6.png'" alt="">
            </div>
            <div class="numBox">
              <div class="dataNum">
                <p>共 6 项数据</p>
              </div>
              <div class="num">
                <span class="focusButton">上一页</span>
                <p @click="mouseNum('num1')" :class="num1?'isNumP':''">1</p>
                <span class="focusButton">下一页</span>
              </div>
            </div>
          </div>
          <div v-if="tab2" class="tab2">
            <div v-if="num1">
              <img :src="baseUrl+'imges/books/patent1.png'" alt="">
              <img :src="baseUrl+'imges/books/patent2.png'" alt="">
              <img :src="baseUrl+'imges/books/patent3.png'" alt="">
              <img :src="baseUrl+'imges/books/patent4.png'" alt="">
              <img :src="baseUrl+'imges/books/patent6.png'" alt="">
              <img :src="baseUrl+'imges/books/patent7.png'" alt="">
            </div>
            <div v-if="num2">
              <img :src="baseUrl+'imges/books/patent8.png'" alt="">
              <img :src="baseUrl+'imges/books/patent9.png'" alt="">
              <img :src="baseUrl+'imges/books/patent10.png'" alt="">
              <img :src="baseUrl+'imges/books/patent11.png'" alt="">
              <img :src="baseUrl+'imges/books/patent12.png'" alt="">
              <img :src="baseUrl+'imges/books/patent13.png'" alt="">
            </div>
            <div v-if="num3">
              <img :src="baseUrl+'imges/books/patent14.png'" alt="">
              <img :src="baseUrl+'imges/books/patent15.png'" alt="">
              <img :src="baseUrl+'imges/books/patent16.png'" alt="">
              <img :src="baseUrl+'imges/books/patent17.png'" alt="">
              <img :src="baseUrl+'imges/books/patent18.png'" alt="">
              <img :src="baseUrl+'imges/books/patent19.png'" alt="">
            </div>
            <div v-if="num4">
              <img :src="baseUrl+'imges/books/patent20.png'" alt="">
              <img :src="baseUrl+'imges/books/patent21.png'" alt="">
              <img :src="baseUrl+'imges/books/patent22.png'" alt="">
              <img :src="baseUrl+'imges/books/patent23.png'" alt="">
              <img :src="baseUrl+'imges/books/patent24.png'" alt="">
              <img :src="baseUrl+'imges/books/patent25.png'" alt="">
            </div>
            <div v-if="num5">
              <img :src="baseUrl+'imges/books/patent26.png'" alt="">
              <img :src="baseUrl+'imges/books/patent27.png'" alt="">
              <img :src="baseUrl+'imges/books/patent28.png'" alt="">
              <img :src="baseUrl+'imges/books/patent29.png'" alt="">
              <img :src="baseUrl+'imges/books/patent30.png'" alt="">
              <img :src="baseUrl+'imges/books/patent31.png'" alt="">
            </div>
            <div v-if="num6">
              <img :src="baseUrl+'imges/books/patent32.png'" alt="">
              <img :src="baseUrl+'imges/books/patent33.png'" alt="">
              <img :src="baseUrl+'imges/books/patent34.png'" alt="">
              <img :src="baseUrl+'imges/books/patent35.png'" alt="" style="float: left; margin-left:14px">
            </div>
            <div class="numBox">
              <div class="dataNum">
                <p>共 34 项数据</p>
              </div>
              <div class="num">
                <span @click="upNum('tab2')" :class="isUp?'focusButton': ''">上一页</span>
                <p @click="mouseNum('num1', 'tab2')" :class="num1?'isNumP':''">1</p>
                <p @click="mouseNum('num2', 'tab2')" :class="num2?'isNumP':''">2</p>
                <p @click="mouseNum('num3', 'tab2')" :class="num3?'isNumP':''">3</p>
                <p @click="mouseNum('num4', 'tab2')" :class="num4?'isNumP':''">4</p>
                <p @click="mouseNum('num5', 'tab2')" :class="num5?'isNumP':''">5</p>
                <p @click="mouseNum('num6', 'tab2')" :class="num6?'isNumP':''">6</p>
                <span @click="downNum('tab2')" :class="isDown?'focusButton': ''">下一页</span>
              </div>
            </div>
          </div>
          <div v-if="tab3" class="tab3">
            <div v-if="num1">
              <img :src="baseUrl+'imges/books/NewCertification_0.jpg'" alt="">
              <img :src="baseUrl+'imges/books/NewCertification_1.jpg'" alt="">
              <img :src="baseUrl+'imges/books/NewCertification_2.jpg'" alt="">
              <img :src="baseUrl+'imges/books/NewCertification_3.jpg'" alt="">
              <img :src="baseUrl+'imges/books/environment.png'" alt="">
              <img :src="baseUrl+'imges/books/credit.png'" alt="">
            </div>
            <div v-if="num2">
              <img :src="baseUrl+'imges/books/safety.png'" alt="">
              <img :src="baseUrl+'imges/books/enviromentEnglish.png'" alt="">
              <img :src="baseUrl+'imges/books/creditEnglish.png'" alt="">
              <img :src="baseUrl+'imges/books/safetyEnglish.png'" alt="" style="float: left; margin-left:14px">
              <img :src="baseUrl+'imges/books/quality.png'" alt="" style="float: left; margin-left:14px">
            </div>
            <div class="numBox">
              <div class="dataNum">
                <p>共 10 项数据</p>
              </div>
              <div class="num">
                <span @click="upNum('tab3')" :class="isUp?'focusButton': ''">上一页</span>
                <p @click="mouseNum('num1', 'tab3')" :class="num1?'isNumP':''">1</p>
                <p @click="mouseNum('num2', 'tab3')" :class="num2?'isNumP':''">2</p>
                <span @click="downNum('tab3')" :class="isDown?'focusButton': ''">下一页</span>
              </div>
            </div>
          </div>

        </div>
      </div>


    </div>

    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
export default {
  name: "Certificate",
  data() {
    return {
      isUp: true,
      isDown: false,
      tab1: true,
      tab2: false,
      tab3: false,
      thisBookName: '荣誉证书',
      num1: true,
      num2: false,
      num3: false,
      num4: false,
      num5: false,
      num6: false,
      scrollTop: 0, //滚轮高度
      leftProductTop: 0, //侧边导航栏高度
      status: '', //侧边导航栏css定位状态
    }
  },
  components: {
    Header,
    Footer,
  },
  mounted(){
    window.addEventListener("scroll", this.handleScroll, true);
    this.leftProductTop = document.getElementsByClassName('leftProductMobile')[0].offsetTop
  },
  methods: {
    isFocus(type){
      if((type === 'tab3' || type === 'tab2') && this.num1 === true){
        this.isUp = true
        this.isDown = false
      } else if(type === 'tab3' && this.num2 === true){
        this.isUp = false
        this.isDown = true
      } else if(type === 'tab2' && this.num6 === true){
        this.isUp = false
        this.isDown = true
      }
    },

    handleScroll(e){
      this.scrollTop =
          document.documentElement.scrollTop || document.body.scrollTop;
      if(this.scrollTop+40 >= this.leftProductTop){
        this.status = 'fixed'
      } else {
        this.status = ''
      }
    },
    //鼠标事件
    mouseEnter(e, flag) {
      this.num1 = true
      this.num2 = false
      this.num3 = false
      this.num4 = false
      this.num5 = false
      this.num6 = false
      if (e === "tab1") {
        this.tab1 = true;
        this.tab2 = false;
        this.tab3 = false;
        this.thisBookName = '荣誉证书'
      } else if (e === "tab2") {
        this.tab1 = false;
        this.tab2 = true;
        this.tab3 = false;
        this.thisBookName = '专利证书'
      } else if (e === "tab3") {
        this.tab1 = false;
        this.tab2 = false;
        this.tab3 = true;
        this.thisBookName = '资质证书'
      }
      if(flag === 'Top'){
        document.documentElement.scrollTop = 0
      }
      this.isFocus()
    },
    //翻页事件
    mouseNum(e, type){
      if (e === "num1") {
        this.isUp = true
        this.num1 = true;
        this.num2 = false;
        this.num3 = false;
        this.num4 = false;
        this.num5 = false;
        this.num6 = false;
      } else if (e === "num2") {
        this.num1 = false;
        this.num2 = true;
        this.num3 = false;
        this.num4 = false;
        this.num5 = false;
        this.num6 = false;
      } else if (e === "num3") {
        this.num1 = false;
        this.num2 = false;
        this.num3 = true;
        this.num4 = false;
        this.num5 = false;
        this.num6 = false;
      } else if (e === "num4") {
        this.num1 = false;
        this.num2 = false;
        this.num3 = false;
        this.num4 = true;
        this.num5 = false;
        this.num6 = false;
      } else if (e === "num5") {
        this.num1 = false;
        this.num2 = false;
        this.num3 = false;
        this.num4 = false;
        this.num5 = true;
        this.num6 = false;
      } else if (e === "num6") {
        this.num1 = false;
        this.num2 = false;
        this.num3 = false;
        this.num4 = false;
        this.num5 = false;
        this.num6 = true;
      }
      this.isFocus(type)
    },
    //上一页
    upNum(type){
      if(type === 'tab3' && this.num1 !== true){
        this.isUp = true
        this.isDown = false
        this.num1 = true
        this.num2 = false
      } else if (type === 'tab2' && this.num1 !== true){
        this.isDown = false
        let numList = [this.num1, this.num2, this.num3, this.num4, this.num5, this.num6]
        numList.forEach((res, index) => {
          if(res === true){
            numList[index] = false
            numList[index - 1] = true
          }
          if(numList[0] === true){
            this.isUp = true
            this.isDown = false
          }
        })
        this.num1 = numList[0]
        this.num2 = numList[1]
        this.num3 = numList[2]
        this.num4 = numList[3]
        this.num5 = numList[4]
        this.num6 = numList[5]

      }
    },
    //下一页
    downNum(type){
      if(type === 'tab3' && this.num2 !== true){
        this.isUp = false
        this.isDown = true
        this.num1 = false
        this.num2 = true
      } else if (type === 'tab2' && this.num6 !== true){
        this.isUp = false
        let numList = [this.num6, this.num5, this.num4, this.num3, this.num2, this.num1]
        numList.forEach((res, index) => {
          if(res === true){
            numList[index] = false
            numList[index - 1] = true
          }
          if(numList[0] === true){
            this.isUp = false
            this.isDown = true
          }
        })
        this.num1 = numList[5]
        this.num2 = numList[4]
        this.num3 = numList[3]
        this.num4 = numList[2]
        this.num5 = numList[1]
        this.num6 = numList[0]
      }
    }
  }
}
</script>

<style lang="less" scoped>
#certificate {
  background: #FFFFFF;
  .headerBox {
    width: 100%;
    height: 580px;
    position: relative;
    min-width: 1250px;
    h4{
      position: absolute;
      left: 18.7vw;
      top: 312px;
      font-family: Medium;
      font-size: 50px;
      font-weight: 500;
      line-height: 17px;
      color: #FFFFFF;
    }
    p{
      position: absolute;
      left: 18.7vw;
      top: 365px;
      width: 508px;
      text-align: left;
      font-family: Regular;
      font-size: 16px;
      line-height: 36px;
      color: #F2F3FE;
    }
    .imgCertificateHead {
      display: block;
      width: 100%;
      height: 100%;
    }
    .MobileBG{
      display: none;
    }
  }

  .contentBox {
    .navHead{
      display: block;
      width: 100%;
      height: 60px;
      background: #F5F5F5;
      .navHead_text{
        line-height: 60px;
        margin-left: 8vw;
      }
    }
    .productBox {
      width: 1600px;
      display: flex;
      margin: 43px auto;
      justify-content: space-between;
      margin-bottom: 60px;
      .leftProductMobile{
        display: none;
      }
      .leftProductPC {
        width: 376px;
        height: 417px;
        background-color: #F5F5F5;
        font-size: 16px;
        font-weight: 500;
        color: #333333;
        line-height: 36px;
        .tab1Class{
          margin-top: 62px;
        }
        p {
          width: 100%;
          height: 60px;
          line-height: 60px;
          margin-bottom: 10px;
          cursor: pointer;
          transition: all .25s linear;
          font-family: Regular;
          font-size: 16px;
          font-weight: normal;
          letter-spacing: 0em;
          color: #454545;
          &:hover {
            width: 100%;
            height: 60px;
            background: #0564AF;
            border-radius: 0px 0px 0px 0px;
            color: #FFFFFF;
          }
        }

        .isActive {
          width: 100%;
          height: 60px;
          background: #0564AF;
          border-radius: 0px 0px 0px 0px;
          color: #FFFFFF;
        }
      }

      .rightProduct {
        position: relative;
        width: 1192px;
        //height: 751px;
        min-height: 496px;
        background: #FAFAFA;
        margin-bottom: 80px;
        .tab1>div>img{
          width: 364px;
          height: 258px;
          margin: 60px 14px 28px 14px;
        }
        .tab2>div>img{
          width: 364px;
          height: 514px;
          margin: 60px 14px 28px 14px;
        }
        .tab3>div>img{
          width: 367px;
          height: 520px;
          margin: 60px 14px 28px 14px;
        }
        .numBox{
          display: flex;
          justify-content: space-between;
          align-items:  center;
          width: 1145px;
          height: 64px;
          margin: 48px auto 36px;
          .dataNum{
            display: block;
            margin-left: 16px;
            font-family: Regular;
            font-size: 14px;
            line-height: 22px;
            color: rgba(0, 0, 0, 0.6);
          }
          .num{
            display: flex;
            justify-content: right;
            span{
              width: 62px;
              height: 28px;
              background: #FFFFFF;
              margin-left: 9px;
              font-size: 12px;
              font-family: Regular;
              font-weight: 500;
              color: #2967B2;
              line-height: 28px;
              cursor: pointer;
            }
            p{
              width: 28px;
              height: 28px;
              margin-left: 9px;
              background: #FFFFFF;
              color: #2967B2;
              font-size: 12px;
              font-family: Regular;
              font-weight: 500;
              line-height: 28px;
              cursor: pointer;
            }
            .isNumP{
              background: #2967B2;
              color: #FFFFFF;
            }
          }
        }
      }
    }
  }
  .focusButton{
    cursor: not-allowed !important;
  }
  .el-breadcrumb{
    font-size: 14px !important;
  }
  .el-menu-item{
    font-size: 14px;
    font-family: Regular;
    font-weight: 500;
    color: #000000;
    margin-bottom: 12px;
  }
}

@media screen and (min-width: 360px) and (max-width: 750px) {
  html {
    font-size: 140px / 10;
  }
  #certificate{
    background: #FFFFFF;
    width: 100%;
    height: 100%;

    //头部区域
    .headerBox{
      position: relative;
      margin-top: 50px;
      width: 100%;
      height: 100%;
      .imgCertificateHead{
        display: none;
      }
      .MobileBG{
        display: block;
        width: 100%;
      }
      h4{
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 67px;
        width: 133px;
        height: 26px;
        font-family: Medium;
        font-size: 26px;
        font-weight: 500;
        line-height: 17px;
        letter-spacing: 0em;
        color: #FFFFFF;
      }
      p{
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 99px;
        width: 323px;
        height: 52px;
        font-family: Regular;
        font-size: 10px;
        font-weight: normal;
        line-height: 23px;
        text-align: center;
        letter-spacing: 0em;
        color: #F2F3FE;
      }
    }

    //证书导航栏
    .contentBox{
      width: 100%;
      height: 100%;
      background-color: #fff;
      .navHead{
        display: none;
      }
      .productBox{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: left;
        margin: 0;
        color: #333333;
        .leftProductPC{
          display: none;
        }
        .leftProductMobile{
          position: relative;
          display: flex;
          justify-content: left;
          width: 100%;
          box-sizing: border-box;
          padding: 0 5%;
          height: 50px;
          margin: 0 auto;
          background-color: #F5F5F5;
          z-index: 100;
          .tab1Class{
            margin-top: 0;
          }
          p {
            position: relative;
            width: 70px;
            height: 50px;
            line-height: 50px;
            font-family: Regular;
            font-size: 12px;
            font-weight: normal;
            letter-spacing: 0.05em;
            color: #999999;
            &:hover {
              width: 70px;
              height: 50px;
              line-height: 50px;
              background: none;
              border-radius: 0px 0px 0px 0px;
              color: #0564AF;
            }
          }
          //新闻动态标题下的横线
          p:hover:after{
            /* 必须设置 content 属性才能生效 */
            content: '';
            /* border 宽度 */
            width: 50px;
            /* border 高度 */
            height: 2px;
            background-color: #0564AF;
            /* border 位置 absolute(绝对定位) */
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            top: 47px;
            /* 自动内减 */
            box-sizing: border-box;
          }
          .isActive {
            width: 70px;
            height: 50px;
            line-height: 50px;
            background: none;
            border-radius: 0px 0px 0px 0px;
            color: #0564AF;
          }
          .isActive:after{
            /* 必须设置 content 属性才能生效 */
            content: '';
            /* border 宽度 */
            width: 50px;
            /* border 高度 */
            height: 2px;
            background-color: #0564AF;
            /* border 位置 absolute(绝对定位) */
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            top: 47px;
            /* 自动内减 */
            box-sizing: border-box;
          }
        }

        //证书内容区
        .rightProduct{
          position: static;
          width: 100%;
          height: 100%;
          margin-top: 47px;
          background-color: #fff;
          .tab1>div>img{
            width: 90%;
            //height: 226px;
            height: 63vw;
            margin: 0 auto 28px;
          }
          .tab2>div>img{
            width: 90%;
            //height: 514px;
            height: 143vw;
            margin: 0 auto 28px;
          }
          .tab3>div>img{
            width: 90%;
            //height: 520px;
            height: 144vw;
            margin: 0 auto 28px;
          }
          .numBox{
            display: flex;
            justify-content: center;
            width: 90%;
            height: 64px;
            .dataNum{
              display: none;
            }
            .num{
              display: flex;
              justify-content: center;
              span{
                width: 62px;
                height: 28px;
                background: #FFFFFF;
                margin-left: 2px;
                font-size: 12px;
                font-family: Regular;
                font-weight: 500;
                color: #2967B2;
                line-height: 28px;
                cursor: pointer;
              }
              p{
                width: 28px;
                height: 28px;
                margin-left: 2px;
                background: #FFFFFF;
                color: #2967B2;
                font-size: 12px;
                font-family: Regular;
                font-weight: 500;
                line-height: 28px;
                cursor: pointer;
              }
              .isNumP{
                background: #2967B2;
                color: #FFFFFF;
              }
            }
          }

        }
      }
    }


  }
}
</style>
